<template>
  <div class="content">
    <p class="introduction">引言</p>
    <p class="introduction1">
      悦淘（在本隐私政策中，指北京悦淘网络科技北京有限公司非常重视用户的隐私和个人信息保护。本隐私政策针对您使用的悦淘贷款应用以及相关服务中收集、使用个人信息的特殊保护规定。 本隐私政策与您所使用的悦淘贷款服务以及该服务所包括的各种业务功能（以下统称“我们的产品或服务”）息息相关，希望您在使用我们的产品或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量使用简明扼要的表述，以便您更好地理解。您使用及在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享、公开披露您的相关信息。
      如对本隐私政策或相关事宜有任何问题，您可随时通过访问悦淘在线客服系统、或拨打我们的任何一部客服电话等多种方式与我们联系。
    </p>
    <p class="introduction2">
      一、我们如何收集和使用您的个人信息
    </p>
    <p class="">（一）收集的信息类别</p>
    <p><span>与贷款服务相关的个人信息：</span>若您使用悦淘贷款服务，我们可能与贷款服务相关的个人信息，例如身份证信息、银行卡信息、联系方式、人脸头像等。
      我们还可能收集其他各类与个人没有关联的匿名信息。例如，在使用特定服务时，可能收集用户使用功能的点击或浏览等。收集此类信息的目的在于改善我们向您提供的服务。所收集信息的类别和数量取决于您如何使用、加入或参与我们产品和/或服务。
    </p>
    <p>（二）我们如何收集您的信息</p>
    <p> 我们会通过如下五种渠道获取您的个人信息：</p>
    <p>
      1、您直接提供的信息：我们可能收集并存储您在使用悦淘贷款服务时向我们提供的任何信息，包括您在我们页面中在线填写信息时、参与网络社区讨论时、解决争议或就悦淘贷款服务与我们联系时所提供的信息，以及您在使用我们服务过程中产生的关于您的交易和活动的信息。</p>
    <p>2、我们主动收集的信息：在您使用悦淘贷款APP、悦淘贷款微信、关联企业及合作伙伴相关服务过程中，我们会收集您的计算机、手机及其他访问设备发送给我们的信息。</p>
    <p>
      3、我们从其他来源取得的信息：在取得您同意的前提下，我们还可能从悦淘贷款关联公司及合作伙伴、信用机构及依法成立并合法留存您的相关信息的第三方机构获取您的相关信息。 我们可能从第三方获取您授权共享的账户信息，并在您同意本隐私政策后通过第三方账户直接登录并使用我们的产品或服务。我们将会依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。在取得您同意的前提下，我们可能从合法持有您个人信息的第三方机构获得您的个人信息。</p>
    <p>4、向行政机关、司法机关查询、打印、留存的信息；</p>
    <p>5、其他从合法途径获得的信息。</p>
    <p>（三）我们如何使用您的信息</p>
    <p> 我们收集您信息的目的主要是为了向您提供安全、高效以及个性化的服务体验。我们会出于以下目的使用您的个人信息：</p>
    <p> 1、进行实名制管理</p>
    <p> 您在使用悦淘服务时我们需要首先认证您身份的真实性，为此您需要提供您的身份信息、银行卡信息及手机号。
      我们会将上述信息提交给合法持有您上述信息的第三方身份验证机构，以便对您所提供信息的准确性进行核对。如果您不提供上述信息则无法使用根据中国相关法律 法规必须进行实名制管理的相关服务，例如：支付、理财、贷款、保险等。</p>
    <p> 2、进行资格、信用及偿付能力审核</p>
    <p>
      您在使用悦淘贷款服务时，我们会使用您的个人基本信息、个人身份信息、个人财产信息及其他在具体业务开展过程中基于您的同意而采集或从第三方获取的信息以评估您的信用状况，进行风险管理及控制。同时，检测、预防或修复欺诈或其他潜在的非法活动。同时，在您享受悦淘贷款服务的过程中，我们会持续性的利用上述信息检测，防止或纠正违反相关法律法规或适用的用户协议的行为。</p>
    <p> 3、为您提供您选择的悦淘贷款相关服务</p>
    <p>
      在您使用悦淘贷款相关服务，我们会使用您的个人身份信息、个人财产信息及其他在业务具体开展过程中基于您的同意而采集的信息对我们产品或服务进行适用性评估、验证交易真实性、处理产品或服务请求以及完成交易指令并向您发送交易通知等。还将用于比较信息的准确性并与第三方进行验证。同时，为了遵守相关法律法规及监管规定，也为了便于您查询交易记录或历史状态，我们会将您使用悦淘贷款时的身份信息、交易信息及行为信息进行存档，并严格按照法律法规的规定对这些信息进行妥善保管。</p>
    <p> 4、 提供客户服务及进行投诉处理</p>
    <p> 我们的客服系统会使用您的账号信息和交易信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您交易信息相关的客服与售后服务时，我们将会查询您的交易信息。
      为了保证您及他人的合法权益，如您被他人投诉或投诉他人，我们会将您的姓名及身份证号码、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。</p>
    <p> 5、改进我们的产品以为您提供更为个性化的服务</p>
    <p>
      我们可能会收集您的交易信息、浏览信息、您的关注信息进行数据分析以形成用户画像，用来将您感兴趣的产品或服务信息展示给您。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。</p>
    <p> 6、保障交易安全所必须的功能</p>
    <p>
      为提高您使用我们的产品或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、交易信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于悦淘贷款系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</p>
    <p> 7、进行营销活动</p>
    <p> 我们可能会利用您提供的以及我们自行采集的用户信息为您推荐个性化的产品或服务。如果您不想接收我们发送的营销信息，您可以通过设置您的习惯来关闭相关推送功能。</p>
    <p> 8、处理双方纠纷</p>
    <p> 因您与我们发生纠纷未能协商解决的，悦淘贷款会将您的个人信息提供给催收公司、律师事务所、法院、仲裁委员会或其他组织机构。</p>
    <p> 9、收集用户反馈或诊断、解决用户问题</p>
    <p> 您选择提供的反馈对帮助悦淘改进我们的服务而言极为珍贵。为了跟踪您选择提供的反馈，我们可能会使用您所提供的个人信息与您联系，并保留记录。并根据您提供的日志定位问题并解决问题。</p>
    <p> 10、发送通知</p>
    <p> 我们可能会不时使用您的个人信息来发送重要通知，例如有关购买保险、理财、贷款的通信和我们的条款、条件和政策变更。</p>
    <p>（四）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
    <p> 1、与国家安全、国防安全有关的；</p>
    <p> 2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p> 3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p> 4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p> 5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
    <p> 6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p> 7、根据您的要求签订合同所必需的；</p>
    <p> 8、为合法的新闻报道所必需的；</p>
    <p> 9、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
    <p> 10、法律法规规定的其他情形。</p>
    <p>（五）您个人信息使用的规则</p>
    <p> 1、我们会根据本隐私政策的约定并为实现我们的产品或服务功能对所收集的个人信息进行使用。</p>
    <p>
      2、请您注意，您在使用我们的产品或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并按照相关法律法规的要求删除您的个人信息或进行匿名化处理。</p>
    <p> 3、我们会对我们的产品或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
    <p> 4、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
    <p>三、我们如何共享、转让、公开披露您的个人信息</p>
    <p>（一）共享</p>
    <p>1、我们不会与悦淘贷款（及相关关联公司或者相关服务提供的主体）以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
    <p> （1）事先获得您明确的同意或授权；</p>
    <p> 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
    <p>
      （2）为建立信用体系，向第三方征信机构提交您在我们服务过程中产生的相关信息，包括个人基本信息，个人敏感信息、反映个人信用的其他信息。您同意并授权我们向征信机构发送您的不良信用信息，且不再另行通知您。借款人不可撤销地授权并同意小贷公司有权在小贷公司及其关联企业旗下任一网站、其他媒体和小贷公司认为必要的区域范围内的任意位置公示借款人违反本合同的相关信息，包括但不限于姓名/名称、肖像、证件号码、住址、具体违约状况等信息；借款人同意小贷公司及其关联企业有权停止向借款人提供任何服务，因此给借款人造成损失的，前述主体均不承担赔偿责任。</p>
    <p> （3）在法律法规允许的范围内，为维护悦淘贷款、悦淘贷款的关联公司或合作伙伴、您或其他悦淘贷款用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
    <p> （4）只有共享您的信息，才能实现我们的产品或服务的核心功能或提供您需要的服务；</p>
    <p> （5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p> （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
    <p> （7）基于学术研究而使用；</p>
    <p> （8）基于符合法律法规的社会公共利益而使用。</p>
    <p>2、为了顺利地从事商业经营，以向您提供产品和服务的全部功能，我们可能不时向其他的悦淘的关联公司共享您的信息。</p>
    <p>3、我们可能会向合作伙伴等第三方共享您的交易信息、 账户信息及设备信息等个人信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的
      个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
    <p> （1）提供技术、咨询服务的供应商。我们可能会将您的
      个人信息共享给支持我们提供服务的第三方。这些机构包括为我们提供基础设施技术服务、数据处理、贷款审批和客户服务等的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。</p>
    <p> （2）合作贷款机构，这些机构可以与我们联合研发并提供贷款产品。除非您同意将这些信息用于其他用途，否则这些贷款机构不得将此类信息用于悦淘贷款相关产品之外的其他目的。</p>
    <p>4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
    <p>
      5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护悦淘贷款、您或其他悦淘贷款客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，我们可能会与其他公司和组织交换您的个人信息、交易信息及活动信息。不过,这并代表我们会违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</p>
    <p>（二）转让</p>
    <p> 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p> 1、事先获得您明确的同意或授权；</p>
    <p> 2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
    <p> 3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
    <p> 4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
    <p>（三）公开披露</p>
    <p> 我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
    <p> 1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p> 2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。</p>
    <p>四、安全保护措施</p>
    <p> 1、我们保护您个人信息的技术与措施</p>
    <p> 我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
    <p> 我们会采用符合业界标准的安全防护措施以及行业内通行的安全技术来防止您的个人信息遭到未经授权的访问、修改,避免您的个人信息泄露、损坏或丢失：</p>
    <p> 我们通过建立数据分类分级、数据安全管理规范、安全开发规范来管理规范个人信息的存储和使用：</p>
    <p> （1）我们采用严格的数据访问权限和多重身份认证技术控制和保护个人信息，通过与信息接触者签署严格的保密协议、监控和审计机制来对数据进行全面安全控制，避免数据被违规使用。</p>
    <p> （2）我们采用代码安全检查、数据访问日志分析技术进行个人信息安全审计。</p>
    <p> （3）我们建立了信息安全委员会并下设信息安全小组，由各小组信息安全专员负责个人信息安全事务。</p>
    <p> （4）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
    <p> 2、安全事件处置</p>
    <p> （1）为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，
      针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
    <p>
      （2）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
    <p>五、您如何管理您的个人信息</p>
    <p>
      悦淘贷款非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您可以根据悦淘贷款的隐私权设置实现您的权利，或者发送邮箱至kefu@yuetao.group，我们会按照法律规定保护您的权利。</p>
    <p>六、优先顺序</p>
    <p> 本隐私政策针对悦淘贷款应用软件以及相关服务，若本隐私政策与悦淘隐私政策之间存在不一致的，以本隐私政策为准；若本隐私政策未约定的，以悦淘隐私政策约定为准。</p>
  </div>
</template>

<script>
export default {
  name: 'serviceIndex'
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  text-align: left;
  font-family: "PingFang SC Regular";
}

.introduction {
  margin-bottom: 20px;
}

p {
  line-height: 20px;
}
</style>
